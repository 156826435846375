<template>
  <div class="all-exams-page mx-2">
    <v-row justify="center">
      <v-col md="6">
        <div v-if="nextExam">
          <h3 class="mt-2">Next Exam</h3>
          <v-card class="my-2" color="primary">
            <v-list-item dark>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">{{
                  nextExam.subject.name
                }}</v-list-item-title>

                <v-list-item-title class="font-weight-medium">{{
                  nextExam.title
                }}</v-list-item-title>

                <v-list-item-subtitle v-if="nextExam.description"
                  v-html="TextHelper.linkify(nextExam.description)"
                >
                }}</v-list-item-subtitle>

                <v-list-item-subtitle class="text-wrap"
                  ><span
                    v-if="
                      moment(nextExam.start_datetime).date() ==
                      moment(nextExam.end_datetime).date()
                    "
                    >{{ moment(nextExam.start_datetime).format("lll") }} -
                    {{ moment(nextExam.end_datetime).format("hh:mm A") }}</span
                  ><span v-else
                    >{{ moment(nextExam.start_datetime).format("lll") }} -
                    {{ moment(nextExam.end_datetime).format("lll") }}</span
                  >
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon class="align-self-center">
                <intract-drop-down-menu
                  v-if="
                    (nextExam.created_by.id == currentUser.id ||
                      currentUser.is_admin) &&
                    !moment().isAfter(moment(nextExam.start_datetime))
                  "
                  :options-list="getDropdownOptions(nextExam)"
                />
              </v-list-item-icon>
            </v-list-item>
          </v-card>
        </div>
        <div>
          <h3 class="mt-2">Upcoming Exams</h3>
        </div>
        <intract-smart-list
          v-if="shouldProceed"
          ref="exams-smart-list"
          disable-search
          paginated
          :endpoint="examsEndpoint"
          @itemsReceived="initNextExam"
        >
          <template v-slot:list-item="{ item: exam }">
            <v-card class="my-2">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">{{
                    exam.subject.name
                  }}</v-list-item-title>

                  <v-list-item-title class="font-weight-medium">{{
                    exam.title
                  }}</v-list-item-title>

                  <v-list-item-subtitle v-if="exam.description"
                  v-html="TextHelper.linkify(exam.description)"
                  ></v-list-item-subtitle>

                  <v-list-item-subtitle class="text-wrap"
                    ><span
                      v-if="
                        moment(exam.start_datetime).date() ==
                        moment(exam.end_datetime).date()
                      "
                      >{{ moment(exam.start_datetime).format("lll") }} -
                      {{
                        moment(exam.end_datetime).format("hh:mm A")
                      }}</span
                    ><span v-else
                      >{{ moment(exam.start_datetime).format("lll") }} -
                      {{ moment(exam.end_datetime).format("lll") }}</span
                    >
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon class="align-self-center">
                  <intract-drop-down-menu
                    v-if="
                      (exam.created_by.id == currentUser.id ||
                        currentUser.is_admin) &&
                      !moment().isAfter(moment(exam.start_datetime))
                    "
                    :options-list="getDropdownOptions(exam)"
                  />
                </v-list-item-icon>
              </v-list-item>
            </v-card>
          </template>
          <template v-slot:paginated-no-items-found>
            <p class="mb-2">No Exams Found!</p>
            <v-btn
              color="primary"
              v-if="!currentUser.is_student"
              @click="createEditExamSheet.visible = true"
              >Create An Exam</v-btn
            >
          </template>
        </intract-smart-list>
      </v-col>
    </v-row>
    <!-- intract create exam sheet -->
    <intract-create-edit-sheet
      v-if="formFields"
      key="exam-create-edit-sheet"
      ref="exam-create-edit-sheet"
      :title="createEditExamSheet.editId ? 'Edit Exam' : 'Create Exam'"
      description="Link an online meeting from Google Meet, Zoom or your favourite video conferencing platform."
      persistent
      :visible="createEditExamSheet.visible"
      @close="
        createEditExamSheet.visible = false;
        createEditExamSheet.editId = null;
      "
      :fields="formFields"
      :endpoint="endpoints.examPosts"
      :edit-id="createEditExamSheet.editId"
      :data-object="createEditExamSheet.obj"
      create-success-message="Exam successfully scheduled!"
      edit-success-message="Exam successfully edited!"
      @objectCreated="onRefresh"
      @updateObject="(obj) => (createEditExamSheet.obj = obj)"
    >
    </intract-create-edit-sheet>
    <!-- create conference fab -->
    <v-fab-transition>
      <v-btn
        v-if="!isLoading && !currentUser.is_student"
        color="primary"
        @click="createEditExamSheet.visible = true"
        dark
        right
        bottom
        fixed
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <choose-room-dialog
      :visible="showRoomDialog"
      @roomSelected="
        (r) => {
          selectedRoom = r;
          showRoomDialog = false;
        }
      "
    />
    <confirmation-dialog
      title="Are you sure you want to delete this exam?"
      description="This action cannot be undone."
      :visible="showDeleteDialog"
      delete-dialog
      @successCallback="deleteExam"
      @failureCallback="showDeleteDialog = false"
    />
  </div>
</template>

<script>
import TextHelper from '@utils/text_helper';
import Mixins from "@utils/mixins";
import IntractDropDownMenu from "@components/generics/IntractDropDownMenu";
import ConfirmationDialog from "@components/dialogs/ConfirmationDialog";
import IntractSmartList from "@components/generics/IntractSmartList";
import moment from "moment";
import ChooseRoomDialog from "@components/dialogs/ChooseRoomDialog";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";

export default {
  name: "AllExams",
  mixins: [Mixins.essentials, Mixins.pullToRefresh],
  components: {
    IntractSmartList,
    ChooseRoomDialog,
    IntractDropDownMenu,
    IntractCreateEditSheet,
    ConfirmationDialog,
  },
  data() {
    return {
      TextHelper,
      moment: moment,
      currentDate: Date.now(),
      selectedRoom: null,
      toBeDeletedExam: null,
      showDeleteDialog: false,
      showRoomDialog: false,

      nextExam: null,

      createEditExamSheet: {
        visible: false,
        createSuccessMessage: "Exam created successfully!",
        editId: null,
        obj: {
          creator: null,
          institution: null,
          room: null,
        },
      },
    };
  },
  computed: {
    shouldProceed() {
      return (
        ((this.currentUser.is_admin || this.currentUser.is_student) &&
          this.selectedRoom) ||
        (this.currentUser.is_faculty && !this.currentUser.is_admin)
      );
    },

    examsEndpoint() {
      if (!this.shouldProceed) return;
      var url = this.Helper.addUrlParams(this.endpoints.examPosts, [
        "end_datetime__gte=" + moment(this.currentDate).format(this.Helper.dateURLFormat),
        "ordering=start_datetime",
      ]);
      if (this.currentUser.is_student || this.currentUser.is_admin)
        return this.Helper.addUrlParams(
          url,
          "subject__room=" + this.selectedRoom.id
        );
      // if faculty
      return this.Helper.addUrlParams(
        url,
        "subject__faculty__id=" + this.currentUser.id
      );
    },

    subjectsEndpoint() {
      if (!this.shouldProceed) return null;
      if (this.currentUser.is_student || this.currentUser.is_admin)
        return this.Helper.addUrlParams(
          this.endpoints.subjectViewSet,
          "room=" + this.selectedRoom.id
        );
      // if faculty
      return this.Helper.addUrlParams(
        this.endpoints.subjectViewSet,
        "faculty=" + this.currentUser.id
      );
    },

    formFields() {
      if (!this.shouldProceed) return null;
      return {
        title: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Title",
          required: true,
          md: 6,
          max: 255,
        },
        subject: {
          fieldType: CreateEditFieldTypes.SELECT,
          label: "Subject",
          md: 6,
          required: true,
          endpoint: this.subjectsEndpoint,
          items: [],
          itemText: "name",
          itemValue: "id",
          itemSubtitle: "room.sections",
        },

        description: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Description",
          required: false,
          md: 12,
          max: 255,
        },

        start_datetime: {
          fieldType: CreateEditFieldTypes.DATETIMEPICKER,
          textModel: null,
          min: new Date(),
          label: "Start Datetime",
          required: true,
          md: 6,
          customRules: [
            (v) =>
              !v ||
              !this.createEditExamSheet.obj.end_datetime ||
              moment(this.createEditExamSheet.obj.end_datetime).isAfter(
                moment(this.createEditExamSheet.obj.start_datetime)
              ) ||
              "Start Datetime should be before End Datetime",

            (v) =>
              !v ||
              moment(this.createEditExamSheet.obj.start_datetime).isAfter(
                moment()
              ) ||
              "Start Datetime cannot be before the current time.",
          ],
        },
        end_datetime: {
          fieldType: CreateEditFieldTypes.DATETIMEPICKER,
          textModel: null,
          hide: this.createEditExamSheet.obj.start_datetime == null,
          min: new Date(this.createEditExamSheet.obj.start_datetime),
          label: "End Datetime",
          required: true,
          md: 6,
          customRules: [
            (v) =>
              !v ||
              moment(this.createEditExamSheet.obj.end_datetime).isAfter(
                moment(this.createEditExamSheet.obj.start_datetime)
              ) ||
              "End Datetime should be after Start Datetime",
          ],
          // valid: () => moment(this.createEditConferenceSheet.obj.end_datetime).isAfter(this.createEditConferenceSheet.obj.start_datetime)
        },
      };
    },
  },
  methods: {
    async initNextExam(allExamsList) {
      if (allExamsList.length) {
        // this.nextExam = allExamsList[0];
        this.nextExam = allExamsList[0];
        if (this.$refs["exams-smart-list"])
          this.$refs["exams-smart-list"].removeItem(allExamsList[0].id);
      }
    },
    // setRoomBeforeSubmission(obj) {
    //   obj.room=obj.subject.room.id
    //   return obj
    // },
    getDropdownOptions(exam) {
      var self = this;
      return [
        {
          obj: exam,
          value: 2,
          text: "Edit",
          icon: "mdi-pencil",
          type: "edit",
          action() {
            self.createEditExamSheet.editId = exam.id;
            self.createEditExamSheet.visible = true;
          },
        },
        {
          obj: exam,
          value: 3,
          text: "Delete",
          icon: "mdi-delete",
          type: "delete",
          action() {
            self.toBeDeletedExam = exam;
            self.showDeleteDialog = true;
          },
        },
      ];
    },

    async deleteExam() {
      var examId = this.toBeDeletedExam.id;
      var url = this.endpoints.examPosts + examId + "/";
      this.toBeDeletedExam = null;
      this.showDeleteDialog = false;
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        this.onRefresh();
        this.showSnackbar({
          title: "Exam has successfully been deleted!",
          type: "success",
        });
      }
    },

    async onRefresh() {
      this.currentDate = new Date();
      this.nextExam = null;
      var el = this.$refs["exams-smart-list"];
      if (el) el.getItems();
    },
  },
  created() {
    this.showRoomDialog = this.currentUser.is_admin; //only show room dialog when user is admin
    this.createEditExamSheet.obj.created_by = this.currentUser.id;
    this.createEditExamSheet.obj.institution = this.currentInstitution.id;
  },
};
</script>




